<template>
    <div class="container checkout">
        <h2 class="text-center">{{ $t('Products') }}</h2>
        <div class="products" v-if="products && products.length">
            <ProductListItem v-for="(product, i) in products" :key="i" :product="product" @cartQuantity="cartQuantity"/>
        </div>
        <div class="text-center">
            <router-link to="/" class="btn btn-default-inverted">
                <i class="fa fa-plus-circle"></i> {{ $t('Add products') }}
            </router-link>
        </div>
        <div class="delivery-address text-center" v-if="cartItems > 0">
            <hr>
            <h2>{{ $t('Delivery address') }}</h2>
            <div v-if="order && order.shipping_address">
                <p>
                    {{ formatAddress(order.shipping_address) }}
                </p>
                <div>
                    <button type="button" class="btn btn-default-inverted" @click="showAddressesModal = true">
                        <i class="fa fa-map-marker" aria-hidden="true"></i> {{ $t('Change address') }}
                    </button>
                </div>
            </div>
            <div v-else>
                <p>{{ $t('Not set.') }}</p>
                <div>
                    <button type="button" class="btn btn-default-inverted" @click="showAddressesModal = true">
                        <i class="fa fa-map-marker" aria-hidden="true"></i> {{ $t('Set delivery address') }}
                    </button>
                </div>
            </div>
        </div>
        <div class="payment-methods text-center" v-if="paymentMethods && paymentMethods.length && cartItems > 0">
            <hr>
            <h2>{{ $t('Payment method') }}</h2>
            <small class="text-danger" v-if="errors.paymentMethod">{{ errors.paymentMethod }}</small>
            <div class="checklist">
                <div v-for="(payment, i) in paymentMethods" :key="i" class="checklist-item" :class="{ selected: order && order.payment_method && order.payment_method.id === payment.id }" @click="paymentMethod = payment">
                    <div class="check">
                        <i class="fa fa-check-circle-o text-success" aria-hidden="true" v-if="order && order.payment_method && order.payment_method.id === payment.id"></i>
                        <i class="fa fa-circle-o" aria-hidden="true" v-else></i>
                    </div>
                    <div class="text">
                        <strong>{{ payment.name }}</strong>
                        <small v-if="payment.description" v-html="payment.description"></small>
                        <small class="price" v-if="payment.price > 0">
                            <i class="fa fa-money"></i> {{ formatPrice(payment.price) }} RON
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="shipping-methods text-center" v-if="shippingMethods && shippingMethods.length && cartItems > 0">
            <hr>
            <h2>{{ $t('Shipping method') }}</h2>
            <small class="text-danger" v-if="errors.shippingMethod">{{ errors.shippingMethod }}</small>
            <div class="checklist">
                <div v-for="(transport, i) in shippingMethods" :key="i" class="checklist-item" :class="{ selected: order && order.transport && order.transport.id === transport.id }" @click="shippingMethod = transport">
                    <div class="check">
                        <i class="fa fa-check-circle-o text-success" aria-hidden="true" v-if="order && order.transport && order.transport.id === transport.id"></i>
                        <i class="fa fa-circle-o" aria-hidden="true" v-else></i>
                    </div>
                    <div class="text">
                        <strong>{{ transport.name }}</strong>
                        <small v-if="transport.description" v-html="transport.description"></small>
                        <small v-if="transport.transit_duration"><i class="fa fa-clock-o"></i> {{ transport.transit_duration }}</small>
                        <small class="price" v-if="transport.price > 0">
                            <i class="fa fa-money"></i> {{ formatPrice(transport.price) }} RON
                        </small>
                        <small class="price" v-else>
                            <i class="fa fa-money"></i> {{ $t('FREE') }}
                        </small>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-center" v-if="cartItems > 0">
            <hr>
            <h2>{{ $t('To pay') }}</h2>
            <div class="price">{{ $t('Products total') }}: {{ formatPrice(cartTotal) }} RON</div>
            <div class="price" v-if="order && order.transport && order.transport.price > 0">{{ $t('Delivery cost') }}: {{ formatPrice(order.transport.price) }} RON</div>
            <div class="price" v-if="order && order.payment_method && order.payment_method.price > 0">{{ $t('Payment fee') }}: {{ formatPrice(order.payment_method.price) }} RON</div>
            <div class="total-price">{{ $t('Order total') }}: {{ formatPrice(orderTotal) }} RON</div>
        </div>
        <div class="text-center" v-if="cartItems > 0">
            <hr>
            <button type="button" class="btn btn-lg btn-success btn-block-xs-portrait" @click="saveOrder" :disabled="isSaving">
                {{ $t('Finish order') }}
                <i class="fa fa-flag-o" v-if="!isSaving"></i>
                <i class="fa fa-spinner fa-spin" aria-hidden="true" v-if="isSaving"></i>
            </button>
        </div>
        <AddressesModal v-if="showAddressesModal" @close="closeAddressesModal" @save="setAddress"/>
    </div>
</template>

<script>

import {mapGetters} from "vuex";
import ProductListItem from "@/components/ProductListItem";
import AddressModal from "@/components/AddressModal";
import AddressesModal from "@/components/account/AddressesModal";
import {formatAddress, formatPrice} from "@/utils";

export default {
    name: 'Checkout',
    components: {AddressesModal, AddressModal, ProductListItem},
    computed: {
        ...mapGetters({
            products: 'GET_CART',
            order: 'GET_ORDER',
            cartItems: 'GET_CART_ITEMS',
            cartTotal: 'GET_CART_TOTAL',
            customer: 'GET_CUSTOMER'
        }),
        orderTotal() {
            let total = parseFloat(this.cartTotal)
            if (this.order && this.order.transport) {
                total += parseFloat(this.order.transport.price)
            }
            if (this.order && this.order.payment_method) {
                total += parseFloat(this.order.payment_method.price)
            }
            return total
        }
    },
    data() {
        return {
            showAddressesModal: false,
            paymentMethods: [],
            paymentMethod: null,
            shippingMethods: [],
            shippingMethod: null,
            isSaving: false,
            loading: false,
            errors: {}
        }
    },
    mounted() {
        document.title = this.$t('Checkout') + ' - Rockstadt Chefs'
        this.$scrollToTop()
        this.getPaymentMethods()
        this.getShippingMethods()
        if (this.order && this.order.payment_method) {
            this.paymentMethod = this.order.payment_method
        }
        if (this.order && this.order.transport) {
            this.shippingMethod = this.order.transport
        }
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Checkout') + ' - Rockstadt Chefs'
            this.getPaymentMethods()
            this.getShippingMethods()
        },
        paymentMethod() {
            this.$store.dispatch('setOrderData', {
                ...this.order,
                payment_method: this.paymentMethod
            })
        },
        shippingMethod() {
            this.$store.dispatch('setOrderData', {
                ...this.order,
                transport: this.shippingMethod
            })
        },
        products() {
            this.getPaymentMethods()
            this.getShippingMethods()
        }
    },
    methods: {
        cartQuantity(product, value) {
            if (value > 0) {
                this.$store.dispatch('addToCart', product)
            } else {
                this.$store.dispatch('removeFromCart', product)
            }
        },
        closeAddressesModal() {
            this.showAddressesModal = false
        },
        setAddress(address) {
            this.$store.dispatch('setOrderData', {
                ...this.order,
                shipping_address: address,
                invoice_address: address
            })
            this.closeAddressesModal()
        },
        isValid() {
            this.errors = {}
            if (!this.customer) {
                this.$store.commit('SET_REDIRECT_URL', '/checkout')
                this.$router.push('/sign-in')
                return false
            } else if (!this.order || !this.order.shipping_address) {
                this.showAddressesModal = true
                return false
            } else {
                if (!this.order || !this.order.payment_method) {
                    this.errors.paymentMethod = this.$t('Please select a payment method!')
                }
                if (!this.order || !this.order.transport) {
                    this.errors.shippingMethod = this.$t('Please select a shipping method!')
                }
            }
            return !Object.keys(this.errors).length
        },
        saveOrder() {
            if (!this.isValid()) {
                return false
            }
            this.isSaving = true
            this.$store.dispatch('createCustomerOrder', {
                payment_method: this.order.payment_method,
                shipping_address: this.order.shipping_address,
                invoice_address: this.order.invoice_address,
                subtotal: this.cartTotal,
                transport: this.order.transport,
                products: this.products
            }).then(response => {
                this.isSaving = false
                this.flash(response.data.message, 'success')
                this.$store.dispatch('setOrderData', null)
                this.$store.dispatch('emptyCart')
                this.$router.push('/account/orders')
            }).catch(error => {
                this.isSaving = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        },
        formatAddress(address) {
            return formatAddress(address)
        },
        getPaymentMethods() {
            this.loading = true
            this.$store.dispatch('getPaymentMethods')
                .then(response => {
                    this.loading = false
                    this.paymentMethods = response.data.data
                }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        },
        getShippingMethods() {
            this.loading = true
            this.$store.dispatch('getShippingMethods', {
                amount: this.cartTotal,
                countryIso: this.order && this.order.shipping_address ? this.order.shipping_address.country_code : 'ro'
            })
                .then(response => {
                    this.loading = false
                    this.shippingMethods = response.data.data
                    if (this.shippingMethods.length) {
                        this.shippingMethod = this.shippingMethods[0]
                    }
                }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        },
        formatPrice(price) {
            return formatPrice(price)
        }
    }
}
</script>
