<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Sign in') }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <SignInForm @signedIn="signedIn"/>
                <hr/>
                <div class="row">
                    <div class="col-xs-6">
                        <GoogleLoginButton/>
                    </div>
                    <div class="col-xs-6">
                        <FacebookLoginButton/>
                    </div>
                </div>
                <hr/>
                <p class="text-center">
                    <router-link to="/recover-password">{{ $t('Recover password') }}</router-link>
                    &middot;
                    <router-link to="/sign-up">{{ $t('Sign up') }}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SignInForm from "@/components/SignInForm";
import GoogleLoginButton from "@/components/GoogleLoginButton";
import FacebookLoginButton from "@/components/FacebookLoginButton";

export default {
    name: "SignIn",
    components: {FacebookLoginButton, GoogleLoginButton, SignInForm},
    mounted() {
        document.title = this.$t('Sign in') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Sign in') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        signedIn() {
            if (this.$store.state.redirectUrl) {
                this.$router.push(this.$store.state.redirectUrl)
                this.$store.commit('SET_REDIRECT_URL', null)
            } else {
                this.$router.push('/account')
            }
        }
    }
}
</script>

<style scoped>

</style>
