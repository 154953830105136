<template>
    <div class="container text-center" v-if="customer">
        <h1 class="text-center">{{ $t('Hi') }}, {{ customer.first_name }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <div class="list-group">
                    <router-link to="/account/profile" class="list-group-item"><i class="fa fa-user-circle-o"></i> {{ $t('Profile') }}</router-link>
                    <router-link to="/account/orders" class="list-group-item"><i class="fa fa-history"></i> {{ $t('Order history') }}</router-link>
                    <router-link to="/account/change-password" class="list-group-item"><i class="fa fa-user-secret"></i> {{ $t('Change password') }}</router-link>
                    <a href="#" class="list-group-item text-danger" @click.prevent="signOut()"><i class="fa fa-lock"></i> {{ $t('Sign out') }}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "Account",
    computed: {
        ...mapGetters({
            customer: 'GET_CUSTOMER'
        })
    },
    mounted() {
        document.title = this.$t('Account') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Account') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        signOut() {
            this.$store.dispatch('signOut').then(response => {
                this.$router.push('/')
            }).catch(error => {
                this.$store.commit('SET_CUSTOMER', null)
                this.$router.push('/')
            })
        }
    }
}
</script>

<style scoped>

</style>
