<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Reset password') }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <ResetPasswordForm @sent="onSent"/>
                <hr/>
                <p class="text-center">
                    <router-link to="/sign-in">{{ $t('Sign in') }}</router-link>
                    &middot;
                    <router-link to="/sign-up">{{ $t('Sign up') }}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import RecoverPasswordForm from "@/components/RecoverPasswordForm";
import ResetPasswordForm from "@/components/ResetPasswordForm";

export default {
    name: "ResetPassword",
    components: {ResetPasswordForm, RecoverPasswordForm},
    mounted() {
        if (!this.$route.query.hash) {
            this.flash(this.$t('Hash key is missing!'), 'error')
            this.$router.push('/')
        }

        document.title = this.$t('Reset password') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Reset password') + ' - Rockstadt Chefs'
        }
    },
    data() {
        return {
            message: ''
        }
    },
    methods: {
        onSent(response) {
            this.flash(response.message, 'success')
            if (this.$store.state.redirectUrl) {
                this.$router.push(this.$store.state.redirectUrl)
                this.$store.commit('SET_REDIRECT_URL', null)
            } else {
                this.$router.push('/')
            }
        }
    }
}
</script>

<style scoped>

</style>
