<template>
    <form @submit.prevent="submit()" @change="validate()">
        <div class="form-group" :class="{ 'has-error': errors.email }">
            <input type="email" placeholder="E-mail" class="form-control input-lg" v-model="email">
            <small class="help-block" v-if="errors.email">{{ errors.email }}</small>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-success btn-lg btn-block-xs-portrait" :disabled="isLoading">{{ $t('Recover password') }}
                <i v-if="isLoading" class="fa fa-spinner fa-spin" aria-hidden="true"></i></button>
        </div>
    </form>
</template>

<script>
export default {
    name: "RecoverPasswordForm",
    data() {
        return {
            errors: {},
            email: '',
            isLoading: false
        }
    },
    methods: {
        validate() {
            this.errors = {}
            if (!this.email) {
                this.errors.email = this.$t('Please write your registered e-mail.')
            }
            return !Object.keys(this.errors).length
        },
        submit() {
            if (!this.validate()) {
                return false
            } else {
                this.isLoading = true
                this.$store.dispatch('recoverPassword', {
                    email: this.email
                }).then(response => {
                    this.isLoading = false
                    this.email = ''
                    this.$emit('sent', response.data)
                }).catch(error => {
                    this.isLoading = false
                    if (error.response.status === 405) {
                        this.flash(error.response.data.error, 'error')
                    } else {
                        this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
