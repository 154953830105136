import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {config} from '../../config/config'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	storage: window.localStorage
})

Vue.use(VueAxios, axios.create({
	baseURL: config.API_URL,
	headers: {
		'api-key': config.API_KEY
	}
}))

export default new Vuex.Store({
	state: {
		language: 'ro',
		customer: null,
		order: null,
		cart: [],
		storeCoordinates: {
			lat: 45.64822,
			lng: 25.61113
		},
		deliveryRadius: 20, //km
		redirectUrl: null
	},
	getters: {
		GET_LANGUAGE(state) {
			return state.language
		},
		GET_CUSTOMER(state) {
			return state.customer
		},
		GET_ORDER(state) {
			return state.order
		},
		GET_CART(state) {
			return state.cart
		},
		GET_CART_ITEMS(state) {
			let items = 0
			for (let x = 0; x < state.cart.length; x++) {
				items += state.cart[x].quantity
			}
			return items
		},
		GET_CART_TOTAL(state) {
			let total = 0
			for (let x = 0; x < state.cart.length; x++) {
				total += state.cart[x].quantity * state.cart[x].price
			}
			return total
		},
		GET_REDIRECT_URL(state) {
			return state.redirectUrl
		}
	},
	mutations: {
		SET_LANGUAGE(state, data) {
			state.language = data
			Vue.axios.defaults.headers.common['language'] = data
		},
		SET_CUSTOMER(state, data) {
			state.customer = data
		},
		SET_ORDER_DATA(state, data) {
			state.order = data
		},
		ADD_TO_CART(state, product) {
			const index = state.cart.findIndex(
				p => p.id === product.id && p.combination_key === product.combination_key
			)
			if (index > -1) {
				Vue.set(state.cart, index, {...state.cart[index], quantity: state.cart[index].quantity + 1})
			} else {
				state.cart.push({...product, quantity: 1})
			}
		},
		REMOVE_FROM_CART(state, product) {
			const index = state.cart.findIndex(
				p => p.id === product.id && p.combination_key === product.combination_key
			)
			if (index > -1) {
				if (state.cart[index].quantity === 1) {
					let newCart = []
					for (let x = 0; x < state.cart.length; x++) {
						if (x !== index) {
							newCart.push(state.cart[x])
						}
					}
					Vue.set(state, 'cart', newCart)
				} else {
					Vue.set(state.cart, index, {...state.cart[index], quantity: state.cart[index].quantity - 1})
				}
			}
		},
		EMPTY_CART(state) {
			state.cart = []
		},
		SET_REDIRECT_URL(state, data) {
			state.redirectUrl = data
		}
	},
	actions: {
		setLanguage({commit, state}, data) {
			commit('SET_LANGUAGE', data)
		},
		getNodes: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/nodes', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		setOrderData({commit, state}, data) {
			commit('SET_ORDER_DATA', data)
		},
		addToCart({commit, state}, data) {
			commit('ADD_TO_CART', data)
		},
		removeFromCart({commit, state}, data) {
			commit('REMOVE_FROM_CART', data)
		},
		emptyCart({commit}) {
			commit('EMPTY_CART')
		},
		signIn: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/sign-in', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.data.access_token
					})
					.catch(error => {
						reject(error)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		signUp: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/sign-up', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.data.access_token
					})
					.catch(error => {
						reject(error)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		recoverPassword: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/recover-password', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		confirmEmail: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/confirm-email', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.data.access_token
					})
					.catch(error => {
						reject(error)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		resetPassword: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/reset-password', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
						Vue.axios.defaults.headers.common['x-access-token'] = response.data.data.access_token
					})
					.catch(error => {
						reject(error)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		signOut: async ({commit}) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/sign-out')
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
					.catch(error => {
						reject(error)
						commit('SET_CUSTOMER', null)
						Vue.axios.defaults.headers.common['x-access-token'] = ''
					})
			})
		},
		updateProfile: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('/customer', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		changePassword: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.put('/customer/change-password', data)
					.then(response => {
						resolve(response)
						commit('SET_CUSTOMER', response.data.data)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getCustomerAddresses: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/customer/addresses', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getCustomerOrders: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/customer/orders', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getCustomerOrder: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/customer/order', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		createCustomerOrder: async ({commit, dispatch}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.post('/customer/orders', data)
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						if (error.response.status === 403) {
							dispatch('signOut')
						}
						reject(error)
					})
			})
		},
		getPaymentMethods: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/utils/payment-methods', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		getShippingMethods: async ({commit}, data) => {
			return new Promise((resolve, reject) => {
				Vue.axios.get('/utils/transport', {
					params: data
				})
					.then(response => {
						resolve(response)
					})
					.catch(error => {
						reject(error)
					})
			})
		}
	},
	modules: {},
	plugins: [vuexLocal.plugin]
})
