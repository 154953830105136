<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Order history') }}</h1>
        <Loading v-if="loading"/>
        <div v-else-if="orders.length" class="orders">
            <div class="order-item" v-for="(order,i) in orders" :key="i" @click="goToOrder(order)">
                <div class="details">
                    <div><i class="fa fa-shopping-cart" aria-hidden="true"></i> <strong>#{{ order.ref_no }}</strong>
                    </div>
                    <div><i class="fa fa-calendar" aria-hidden="true"></i> <small>{{ formatDate(order.created_on) }}</small></div>
                    <div><i class="fa fa-money"></i> <small>{{ formatPrice(order.total_amount) }} RON</small></div>
                </div>
                <div class="status text-center" :class="`status-${order.status}`">
                    <i class="fa fa-paper-plane-o" aria-hidden="true" v-if="order.status === 'new'"></i>
                    <i class="fa fa-hourglass-start fa-spin" aria-hidden="true" v-if="order.status === 'pending'"></i>
                    <i class="fa fa-car" aria-hidden="true" v-if="order.status === 'delivered'"></i>
                    <i class="fa fa-flag-checkered" aria-hidden="true" v-if="order.status === 'completed'"></i>
                    <i class="fa fa-times" aria-hidden="true" v-if="order.status === 'canceled'"></i>
                    <div><small>{{ $t(order.status) }}</small></div>
                </div>
            </div>
        </div>
        <div v-else class="text-center text-info">{{ $t('You have no orders yet.') }}</div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Loading from "@/components/Loading";
import {formatDate, formatPrice} from "@/utils";

export default {
    name: "Orders",
    components: {Loading},
    computed: {
        ...mapGetters({
            customer: 'GET_CUSTOMER'
        })
    },
    data() {
        return {
            orders: [],
            loading: false
        }
    },
    mounted() {
        document.title = this.$t('Order history') + ' - Rockstadt Chefs'
        this.$scrollToTop()
        this.getOrders()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Order history') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        getOrders() {
            this.loading = true
            this.$store.dispatch('getCustomerOrders')
                .then(response => {
                    this.loading = false
                    this.orders = response.data.data
                }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        },
        formatDate(date) {
            return formatDate(date, this.$i18n.locale)
        },
        formatPrice(price) {
            return formatPrice(price)
        },
        goToOrder(order) {
            this.$router.push('/account/order/' + order.id)
        }
    }
}
</script>

<style scoped>

</style>
