<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Sign up') }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <SignUpForm @signedUp="signedUp"/>
                <hr/>
                <p class="text-center">
                    <router-link to="/sign-in">{{ $t('Sign in') }}</router-link>
                    &middot;
                    <router-link to="/recover-password">{{ $t('Recover password') }}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import SignUpForm from "@/components/SignUpForm";

export default {
    name: "SignUp",
    components: {SignUpForm},
    mounted() {
        document.title = this.$t('Sign up') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Sign up') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        signedUp() {
            if (this.$store.state.redirectUrl) {
                this.$router.push(this.$store.state.redirectUrl)
                this.$store.commit('SET_REDIRECT_URL', null)
            } else {
                this.$router.push('/account')
            }
        }
    }
}
</script>

<style scoped>

</style>
