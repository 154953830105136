<template>
    <div id="app">
        <header>
            <div class="container text-center">
                <div id="logo">
                    <router-link to="/">
                        <img src="@/assets/logo.png" alt="Rockstadt Chefs">
                    </router-link>
                </div>
            </div>
        </header>
        <section id="main">
            <transition :name="transitionName" mode="out-in">
                <router-view class="child-view"/>
            </transition>
        </section>
        <section id="nav">
            <div class="container">
                <div id="menu">
                    <router-link to="/"><i class="fa fa-list-ul"></i><span>{{ $t('Products') }}</span></router-link>
                    <router-link to="/checkout">
                        <i class="fa fa-shopping-cart"></i><span>{{ $t('Checkout') }}</span><small class="counter">{{ cartItems }}</small>
                    </router-link>
                    <router-link to="/account"><i class="fa fa-user-circle-o"></i><span>{{ $t('Account') }}</span>
                    </router-link>
                </div>
                <router-link to="/checkout" class="btn btn-success btn-lg order-floating-button" v-if="cartItems > 0 && $route.name === 'Home'">
                    {{ $t('Order') }} <strong>{{ cartTotal }} RON</strong>
                </router-link>
            </div>
        </section>
        <footer>
            <div class="container text-center">
                <ul class="list-inline">
                    <li>
                        <a href="#" @click.prevent="changeLanguage('ro')" :class="{ 'text-primary': $root.$i18n.locale === 'ro' }">Romana</a>
                    </li>
                    <li>&middot;</li>
                    <li>
                        <a href="#" @click.prevent="changeLanguage('en')" :class="{ 'text-primary': $root.$i18n.locale === 'en' }">English</a>
                    </li>
                </ul>
            </div>
        </footer>
        <div class="scroll-to-top" @click="$scrollToTop" v-if="pageIsScrolled"><i class="fa fa-chevron-circle-up"></i>
        </div>
        <flash-message></flash-message>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Vue from "vue";

export default {
    name: 'App',
    computed: {
        ...mapGetters({
            cartItems: 'GET_CART_ITEMS',
            cartTotal: 'GET_CART_TOTAL',
            customer: 'GET_CUSTOMER'
        })
    },
    data() {
        return {
            pageIsScrolled: false,
            transitionName: 'fade'
        }
    },
    watch: {
        '$route'(to, from) {
            const toDepth = to.path.split('/').length
            const fromDepth = from.path.split('/').length
            this.transitionName = toDepth < fromDepth ? 'slide-right' : 'slide-left'
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll)
        if (this.$root.$i18n.locale) {
            this.$store.dispatch('setLanguage', this.$root.$i18n.locale)
        }
        if (this.customer) {
            Vue.axios.defaults.headers.common['x-access-token'] = this.customer.access_token
        }
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },
    methods: {
        changeLanguage(lang) {
            this.$root.$i18n.locale = lang
            this.$store.dispatch('setLanguage', lang)
        },
        handleScroll() {
            this.pageIsScrolled = window.scrollY > 100
        }
    }
}
</script>

<style lang="less">
@import "assets/styles/app";
</style>
