import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import store from '@/store/index'
import Checkout from "@/views/Checkout";
import SignIn from "@/views/SignIn";
import SignUp from "@/views/SignUp";
import RecoverPassword from "@/views/RecoverPassword";
import ResetPassword from "@/views/ResetPassword";
import Profile from "@/views/account/Profile";
import Account from "@/views/account/Account";
import Orders from "@/views/account/Orders";
import ChangePassword from "@/views/account/ChangePassword";
import OrderDetails from "@/views/account/OrderDetails";

Vue.use(VueRouter)

const ifNotAuthenticated = (to, from, next) => {
	if (!store.state.customer) {
		next()
		return
	}
	next('/')
}

const ifAuthenticated = (to, from, next) => {
	if (store.state.customer) {
		next()
		return
	}
	next('/sign-in')
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: Home
	},
	{
		path: '/checkout',
		name: 'Checkout',
		component: Checkout
	},
	{
		path: '/account',
		name: 'Account',
		component: Account,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/account/profile',
		name: 'Profile',
		component: Profile,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/account/orders',
		name: 'Orders',
		component: Orders,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/account/order/:id',
		name: 'Order details',
		component: OrderDetails,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/account/change-password',
		name: 'Change password',
		component: ChangePassword,
		beforeEnter: ifAuthenticated
	},
	{
		path: '/sign-in',
		name: 'Sign in',
		component: SignIn,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/sign-up',
		name: 'Sign up',
		component: SignUp,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/recover-password',
		name: 'Recover password',
		component: RecoverPassword,
		beforeEnter: ifNotAuthenticated
	},
	{
		path: '/reset-password',
		name: 'Reset password',
		component: ResetPassword,
		beforeEnter: ifNotAuthenticated
	}
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
