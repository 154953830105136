<template>
    <div>
        <a href="#" class="google-signup btn btn-default-inverted btn-block" @click.prevent="loginWithGoogle">
            <i class="fa fa-google"></i> Google login
        </a>
    </div>
</template>

<script>
export default {
    name: "GoogleLoginButton",
    methods: {
        loginWithGoogle() {
            this.$gAuth
                .signIn()
                .then(GoogleUser => {
                    // on success do something
                    //.log('GoogleUser', GoogleUser)
                    //.log('getId', GoogleUser.getId())
                    //console.log('getBasicProfile', GoogleUser.getBasicProfile())
                    //console.log('getAuthResponse', GoogleUser.getAuthResponse())
                    const profile = GoogleUser.getBasicProfile()
                    const customerData = {
                        first_name: profile.getGivenName(),
                        last_name: profile.getFamilyName(),
                        email: profile.getEmail(),
                        avatar: profile.getImageUrl(),
                        google_id: profile.getId()
                    }
                    console.log(customerData)
                })
                .catch(error => {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                })
        }
    }
}
</script>

<style scoped>

</style>
