import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import en from '@/lang/en.json'
import ro from '@/lang/ro.json'
import VueFlashMessage from 'vue-flash-message'
import VueI18n from 'vue-i18n'
import * as VueGoogleMaps from 'vue2-google-maps'
import {config} from "../config/config";
import GoogleAuth from '@/oauth/google'

Vue.use(GoogleAuth, {
	clientId: config.GOOGLE_CLIENT_ID,
	scope: 'profile email',
	prompt: 'select_account'
})

Vue.use(VueFlashMessage, {
	messageOptions: {
		timeout: 4000,
		pauseOnInteract: true
	}
});

Vue.use(VueI18n)

const messages = Object.assign({en, ro})

const i18n = new VueI18n({
	locale: 'ro',
	fallbackLocale: 'en',
	messages
});

Vue.prototype.$scrollToTop = () => window.scroll({
	top: 0,
	left: 0,
	behavior: 'smooth'
})

Vue.use(VueGoogleMaps, {
	load: {
		key: config.GOOGLE_API_KEY,
		libraries: 'places'
	}
})

Vue.config.productionTip = false

new Vue({
	router,
	i18n,
	store,
	render: h => h(App)
}).$mount('#app')
