<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Change password') }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <ChangePasswordForm @sent="isUpdated"/>
            </div>
        </div>
    </div>
</template>

<script>
import ChangePasswordForm from "@/components/account/ChangePasswordForm";

export default {
    name: "ChangePassword",
    components: {ChangePasswordForm},
    mounted() {
        document.title = this.$t('Change password') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Change password') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        isUpdated(data) {
            this.flash(data.message, 'success')
            this.$router.push('/account')
        }
    }
}
</script>

<style scoped>

</style>
