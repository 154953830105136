<template>
    <div>
        <a href="#" class="facebook-signup btn btn-default-inverted btn-block" @click.prevent="loginWithFacebook">
            <i class="fa fa-facebook-official"></i> Facebook login
        </a>
    </div>
</template>

<script>
import {initFbsdk} from "@/oauth/facebook";

export default {
    name: "FacebookLoginButton",
    async mounted() {
        await initFbsdk()
    },
    methods: {
        loginWithFacebook() {
            window.FB.login(response => {
                window.FB.api('/me?fields=name,email', profile => {
                    console.log(profile)
                })
            }, {
                scope: 'email'
            })
        }
    }
}
</script>

<style scoped>

</style>
