<template>
    <form @submit.prevent="submit()">
        <div class="form-group" :class="{ 'has-error': errors.firstName }">
            <input type="text" :placeholder="$t('First name')" class="form-control input-lg" v-model="firstName">
            <small class="help-block" v-if="errors.firstName">{{ errors.firstName }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.lastName }">
            <input type="text" :placeholder="$t('Last name')" class="form-control input-lg" v-model="lastName">
            <small class="help-block" v-if="errors.lastName">{{ errors.lastName }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.email }">
            <input type="email" placeholder="E-mail" class="form-control input-lg" v-model="email" autocomplete="new-email">
            <small class="help-block" v-if="errors.email">{{ errors.email }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.phone }">
            <input type="tel" :placeholder="$t('Mobile phone')" class="form-control input-lg" v-model="phone">
            <small class="help-block" v-if="errors.phone">{{ errors.phone }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.password }">
            <input type="password" :placeholder="$t('Password')" class="form-control input-lg" v-model="password" autocomplete="new-password">
            <small class="help-block" v-if="errors.password">{{ errors.password }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.repeatPassword }">
            <input type="password" :placeholder="$t('Repeat password')" class="form-control input-lg" v-model="repeatPassword" autocomplete="new-password">
            <small class="help-block" v-if="errors.repeatPassword">{{ errors.repeatPassword }}</small>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-success btn-lg btn-block-xs-portrait" :disabled="isLoading">{{ $t('Sign up') }} <i v-if="isLoading" class="fa fa-spinner fa-spin" aria-hidden="true"></i></button>
        </div>
    </form>
</template>

<script>
export default {
    name: "SignUpForm",
    data() {
        return {
            errors: {},
            email: '',
            password: '',
            repeatPassword: '',
            firstName: '',
            lastName: '',
            phone: '',
            isLoading: false
        }
    },
    methods: {
        validate() {
            this.errors = {}
            if (!this.email) {
                this.errors.email = this.$t('E-mail is required.')
            }
            if (!this.firstName) {
                this.errors.firstName = this.$t('First name is required.')
            }
            if (!this.lastName) {
                this.errors.lastName = this.$t('Last name is required.')
            }
            if (!this.phone) {
                this.errors.phone = this.$t('Mobile phone number is required.')
            }
            if (!this.password) {
                this.errors.password = this.$t('Please write your secret password.')
            }else if (this.password !== this.repeatPassword) {
                this.errors.password = this.$t('Password is not the same with repeat password.')
                this.errors.repeatPassword = this.$t('Value is not the same as password.')
            }
            return !Object.keys(this.errors).length
        },
        submit() {
            if (!this.validate()) {
                return false
            } else {
                this.isLoading = true
                this.$store.dispatch('signUp', {
                    email: this.email,
                    password: this.password,
                    first_name: this.firstName,
                    last_name: this.lastName,
                    phone: this.phone,
                }).then(response => {
                    this.isLoading = false
                    this.$emit('signedUp', response.data)
                }).catch(error => {
                    this.isLoading = false
                    if (error.response.status === 405) {
                        this.flash(error.response.data.error, 'error')
                    } else {
                        this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
