<template>
    <form @submit.prevent="submit()" @change="validate()">
        <div class="form-group" :class="{ 'has-error': errors.password }">
            <input type="password" :placeholder="$t('New password')" class="form-control input-lg" v-model="password">
            <small class="help-block" v-if="errors.password">{{ errors.password }}</small>
        </div>
        <div class="form-group" :class="{ 'has-error': errors.repeatPassword }">
            <input type="password" :placeholder="$t('Repeat new password')" class="form-control input-lg" v-model="repeatPassword">
            <small class="help-block" v-if="errors.repeatPassword">{{ errors.repeatPassword }}</small>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-success btn-lg btn-block-xs-portrait" :disabled="isLoading">{{ $t('Reset password') }}
                <i v-if="isLoading" class="fa fa-spinner fa-spin" aria-hidden="true"></i></button>
        </div>
    </form>
</template>

<script>
export default {
    name: "ResetPasswordForm",
    data() {
        return {
            errors: {},
            password: '',
            repeatPassword: '',
            isLoading: false
        }
    },
    methods: {
        validate() {
            this.errors = {}
            if (!this.password) {
                this.errors.password = this.$t('Please write your secret password.')
            }else if (this.password !== this.repeatPassword) {
                this.errors.password = this.$t('Password is not the same with repeat password.')
                this.errors.repeatPassword = this.$t('Value is not the same as password.')
            }
            return !Object.keys(this.errors).length
        },
        submit() {
            if (!this.validate()) {
                return false
            } else {
                this.isLoading = true
                this.$store.dispatch('resetPassword', {
                    password: this.password,
                    hash: this.$route.query.hash
                }).then(response => {
                    this.isLoading = false
                    this.$emit('sent', response.data)
                }).catch(error => {
                    this.isLoading = false
                    if (error.response.status === 405) {
                        this.flash(error.response.data.error, 'error')
                    } else {
                        this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                    }
                })
            }
        }
    }
}
</script>

<style scoped>

</style>
