import {config} from '@/../config/config'

export const initFbsdk = () => {
	return new Promise(resolve => {
		window.fbAsyncInit = function () {
			FB.init({
				appId: config.FACEBOOK_APP_ID,
				cookie: true, // enable cookies to allow the server to access the session
				xfbml: true, // parse social plugins on this page
				version: 'v9.0' // use graph api version 2.8
			})
		}
	})
}
