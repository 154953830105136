<template>
    <div class="loading">
        <div class="loading__icon"><i class="fa fa-spinner fa-spin fa-2x" aria-hidden="true"></i></div>
        <div class="loading__text">{{ $t('Loading...') }}</div>
    </div>
</template>

<script>
export default {
    name: "Loading"
}
</script>

<style scoped lang="less">
.loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    padding: 150px 0;

    > * {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .loading__text{
        margin-top: 40px;
    }
}
</style>
