<template>
    <div class="container home">
        <Loading v-if="loading"/>
        <div v-else>
            <div class="search-wrapper">
                <div class="categories">
                    <div class="categories__item" :class="{ 'categories__item--selected': !filterCategory }" @click="setCategory(null)">
                        <div class="categories__item__image" style="background-image: url('/images/category-all.jpg')"></div>
                        <span>{{ $t('All') }}</span>
                    </div>
                    <div class="categories__item" v-for="(node,i) in nodes" :key="i" @click="setCategory(node)" :class="{ 'categories__item--selected': filterCategory && node.id === filterCategory.id }">
                        <div class="categories__item__image" :style="{ 'background-image': node.image_url ? 'url(' + node.image_url + ')' : 'url(\'/images/no-image.svg\')' }"></div>
                        <span>{{ node.name }}</span>
                    </div>
                </div>
                <div class="search">
                    <input type="search" placeholder="Search..." class="form-control" v-model="search" @input="filter()">
                </div>
            </div>
            <div class="products" v-if="filteredNodes && filteredNodes.length">
                <NodeListItem v-for="(node,i) in filteredNodes" :key="i" :node="node" @cartQuantity="cartQuantity"/>
            </div>
            <div v-else class="text-center text-danger">No results.</div>
        </div>
    </div>
</template>

<script>

import ProductListItem from "@/components/ProductListItem";
import NodeListItem from "@/components/NodeListItem";
import Loading from "@/components/Loading";

export default {
    name: 'Home',
    components: {Loading, NodeListItem, ProductListItem},
    data() {
        return {
            nodes: null,
            filteredNodes: null,
            categoryNodes: null,
            loading: false,
            filterCategory: null,
            search: ''
        }
    },
    mounted() {
        document.title = this.$t('Order food for delivery') + ' - Rockstadt Chefs'
        document.body.classList.add('home')

        this.getCategories()
        this.$scrollToTop()

        if (this.$route.query.action && this.$route.query.action === 'confirm-email' && this.$route.query.hash) {
            this.confirmEmail(this.$route.query.hash)
        }
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Order food for delivery') + ' - Rockstadt Chefs'
            this.getCategories()
        }
    },
    methods: {
        getCategories() {
            this.loading = true
            this.$store.dispatch('getNodes', {
                items: true
            }).then(response => {
                this.loading = false
                const chefs = response.data.data.find(
                    n => n.identifier === 'chefs'
                )
                if (chefs) {
                    this.nodes = chefs.children
                    this.filteredNodes = this.nodes
                    this.categoryNodes = this.nodes
                }
            }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        },
        setCategory(cat) {
            this.filterCategory = cat
            if (cat) {
                this.categoryNodes = this.nodes.filter(
                    n => n.id === cat.id
                )
            } else {
                this.categoryNodes = this.nodes
            }
            this.filter()
            this.$scrollToTop()
        },
        filter() {
            this.filteredNodes = this.doSearch([], [...this.categoryNodes])
        },
        doSearch(result, categories) {
            for (let x = 0; x < categories.length; x++) {
                let category = {...categories[x]}
                if (this.search) {
                    if (category.products && category.products.length) {
                        category.products = category.products.filter(
                            p => new RegExp(this.search, 'gi').test(p.name)
                        )
                    }
                    if (category.products && category.products.length) {
                        result.push({...category})
                    }
                } else {
                    result.push({...category})
                }

                if (category.children && category.children.length) {
                    result = this.doSearch(result, category.children)
                }
            }
            return result
        },
        cartQuantity(product, value) {
            if (value > 0) {
                this.$store.dispatch('addToCart', product)
            } else {
                this.$store.dispatch('removeFromCart', product)
            }
        },
        confirmEmail(hash) {
            this.$store.dispatch('confirmEmail', {
                hash: hash
            }).then(response => {
                this.flash(response.data.message, 'success')
            }).catch(error => {
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        }
    },
    destroyed () {
        document.body.classList.remove('home')
    }
}
</script>
