import moment from "moment";

export function getDistance(point1, point2) {
	const R = 6371e3 // metres
	const f1 = point1.lat * Math.PI / 180 // φ, λ in radians
	const f2 = point2.lat * Math.PI / 180
	const a1 = (point2.lat - point1.lat) * Math.PI / 180
	const a2 = (point2.lng - point1.lng) * Math.PI / 180

	const a = Math.sin(a1 / 2) * Math.sin(a1 / 2) +
		Math.cos(f1) * Math.cos(f2) *
		Math.sin(a2 / 2) * Math.sin(a2 / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	return parseInt(R * c) || 0 // in metres
}

export const mapStyle = [
	{
		"featureType": "all",
		"elementType": "labels",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"saturation": 36
			},
			{
				"color": "#000000"
			},
			{
				"lightness": 40
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"visibility": "on"
			},
			{
				"color": "#000000"
			},
			{
				"lightness": 16
			}
		]
	},
	{
		"featureType": "all",
		"elementType": "labels.icon",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 20
			}
		]
	},
	{
		"featureType": "administrative",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 17
			},
			{
				"weight": 1.2
			}
		]
	},
	{
		"featureType": "administrative.country",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#e5c163"
			}
		]
	},
	{
		"featureType": "administrative.locality",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#c4c4c4"
			}
		]
	},
	{
		"featureType": "administrative.neighborhood",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#e5c163"
			}
		]
	},
	{
		"featureType": "landscape",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 20
			}
		]
	},
	{
		"featureType": "poi",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 21
			},
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "poi.attraction",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.business",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "poi.business",
		"elementType": "geometry",
		"stylers": [
			{
				"visibility": "on"
			}
		]
	},
	{
		"featureType": "poi.medical",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.park",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.school",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "poi.sports_complex",
		"elementType": "all",
		"stylers": [
			{
				"visibility": "simplified"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#e5c163"
			},
			{
				"lightness": "0"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "geometry.stroke",
		"stylers": [
			{
				"visibility": "off"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#ffffff"
			}
		]
	},
	{
		"featureType": "road.highway",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#e5c163"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 18
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "geometry.fill",
		"stylers": [
			{
				"color": "#575757"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#ffffff"
			}
		]
	},
	{
		"featureType": "road.arterial",
		"elementType": "labels.text.stroke",
		"stylers": [
			{
				"color": "#2c2c2c"
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 16
			}
		]
	},
	{
		"featureType": "road.local",
		"elementType": "labels.text.fill",
		"stylers": [
			{
				"color": "#999999"
			}
		]
	},
	{
		"featureType": "transit",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 19
			}
		]
	},
	{
		"featureType": "water",
		"elementType": "geometry",
		"stylers": [
			{
				"color": "#000000"
			},
			{
				"lightness": 17
			}
		]
	}
]

export function replaceFlags(string, values) {
	if (values) {
		for (let i = 0; i < values.length; i++) {
			string = string.replace('%s', values[i])
		}
	}
	return string
}

export function formatAddress(address) {
	return [address.address_line_1, address.address_line_2 || ''].filter(el => el !== '' && el !== null).join(", ")
}

export function formatPrice (price) {
	const number = parseInt(price) === price ? price : parseFloat(price).toFixed(2)
	return String(number).replace(/(\d)(?=(\d{3})+\b)/g, `$1 `)
}

export function formatDate (date, locale, format) {
	if (!format) {
		format = moment(date).format('YYYY') === moment().format('YYYY') ? 'ddd, DD MMM @ HH:mm' : 'ddd, DD MMM, YYYY @ HH:mm'
	}
	return locale ? moment(date).locale(locale).format(format) : moment(date).format(format)
}
