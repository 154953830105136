<template>
    <div>
        <div class="custom-modal-overlay addresses">
            <div class="custom-modal">
                <div class="custom-modal-head">
                    <div class="custom-modal-title text-primary">{{ $t('Your addresses') }}</div>
                    <div class="custom-modal-close" @click="close()">&times;</div>
                </div>
                <div class="custom-modal-body text-center">
                    <Loading v-if="isLoading"/>
                    <div v-else-if="addresses.length" class="checklist">
                        <div class="checklist-item" v-for="(address, i) in addresses" :key="i" :class="{ selected: selected && selected === address }" @click="setAddress(address)">
                            <div class="check">
                                <i class="fa fa-check-circle-o text-success" aria-hidden="true" v-if="selected && selected === address"></i>
                                <i class="fa fa-circle-o" aria-hidden="true" v-else></i>
                            </div>
                            <div class="text">
                                <strong>
                                    {{ address.address_line_1 }} {{ address.address_line_2 }}
                                    <small>{{ getDistance(address) }} km</small>
                                </strong>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-info">{{ $t('You have no address saved yet.') }}</div>
                </div>
                <div class="custom-modal-footer">
                    <button type="button" class="btn btn-primary-inverted" @click="showAddressModal = true">{{ $t('Add new address') }}</button>
                    <button type="button" class="btn btn-success-inverted" @click="useSelected()" v-if="selected">{{ $t('Use selected') }}</button>
                </div>
            </div>
        </div>
        <AddressModal v-if="showAddressModal" @close="closeAddressModal" @save="saveNewAddress" :modal-title="$t('Set delivery address')"/>
    </div>
</template>

<script>
import AddressModal from "@/components/AddressModal";
import {mapGetters} from "vuex";
import Loading from "@/components/Loading";
import {formatPrice, getDistance} from "@/utils";

export default {
    name: "AddressesModal",
    components: {Loading, AddressModal},
    computed: {
        ...mapGetters({
            customer: 'GET_CUSTOMER',
            order: 'GET_ORDER'
        })
    },
    data() {
        return {
            showAddressModal: false,
            addresses: [],
            isLoading: false,
            selected: null
        }
    },
    mounted() {
        if (this.customer) {
            this.loading = true
            this.$store.dispatch('getCustomerAddresses')
                .then(response => {
                    this.loading = false
                    this.addresses = response.data.data
                }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
            })
        }
    },
    methods: {
        close() {
            this.$emit('close')
        },
        saveNewAddress(address) {
            this.addresses.push(address)
            this.setAddress(address)
            this.closeAddressModal()
        },
        closeAddressModal() {
            this.showAddressModal = false
        },
        setAddress(address) {
            this.selected = address
        },
        useSelected() {
            this.$emit('save', this.selected)
            this.close()
        },
        getDistance(address) {
            const distance = getDistance({
                lat: address.latitude,
                lng: address.longitude
            }, this.$store.state.storeCoordinates)
            return formatPrice(distance / 1000)
        }
    }
}
</script>

<style scoped>

</style>
