<template>
    <div class="custom-modal-overlay address-modal">
        <div class="custom-modal">
            <div class="custom-modal-head">
                <div class="custom-modal-title text-primary"><i class="fa fa-map-marker"></i> {{ modalTitle }}</div>
                <div class="custom-modal-close" @click="close()">&times;</div>
            </div>
            <div class="custom-modal-body">
                <gmap-autocomplete @place_changed="setPlace" :options="{}" class="form-control"></gmap-autocomplete>
                <div class="google-map">
                    <GmapMap
                        :center="{lat: address.latitude, lng: address.longitude}"
                        :zoom="17"
                        :options="mapOptions"
                        map-type-id="roadmap"
                        class="map-item"
                        @click="onPan"
                        ref="mapRef"
                    >
                    </GmapMap>
                    <img src="@/assets/map-marker.svg" alt="" class="google-map-marker">
                </div>
                <div class="p-20">
                    <div class="row">
                        <div class="col-xs-12">
                            <div class="form-group" :class="{ 'has-error': errors.city }">
                                <label>{{ $t('City') }}</label>
                                <input type="text" v-model.trim="address.city" class="form-control"/>
                                <small class="help-block" v-if="errors.city">{{ errors.city }}</small>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="form-group" :class="{ 'has-error': errors.address_line_1 }">
                                <label>{{ $t('Address(line 1)') }}</label>
                                <input type="text" v-model.trim="address.address_line_1" class="form-control" :placeholder="$t('Street name and number')"/>
                                <small class="help-block" v-if="errors.address_line_1">{{ errors.address_line_1 }}</small>
                            </div>
                        </div>
                        <div class="col-xs-12">
                            <div class="form-group" :class="{ 'has-error': errors.address_line_2 }">
                                <label>{{ $t('Address(line 2)') }}</label>
                                <input type="text" v-model.trim="address.address_line_2" class="form-control" :placeholder="$t('Building, apartment, etc.')"/>
                                <small class="help-block" v-if="errors.address_line_2">{{ errors.address_line_2 }}</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-modal-footer">
                <button type="button" class="btn btn-default btn-default-inverted" @click="close()">{{ $t('Cancel') }}</button>
                <button type="button" class="btn btn-success btn-success-inverted" @click="save()">{{ $t('Save address') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
import {gmapApi} from "vue2-google-maps";
import {getDistance, mapStyle, replaceFlags} from "@/utils";

export default {
    name: "AddressModal",
    props: {
        addressRole: {
            type: String,
            default: 'both'
        },
        modalTitle: {
            type: String,
            default: 'Address'
        }
    },
    computed: {
        google: gmapApi
    },
    data() {
        return {
            address: {
                latitude: this.$store.state.storeCoordinates.lat,
                longitude: this.$store.state.storeCoordinates.lng,
                address_line_1: '',
                address_line_2: ''
            },
            errors: {},
            mapOptions: {
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUI: false,
                styles: mapStyle
            },
        }
    },
    updated() {
        if (this.$refs.mapRef) {
            this.$refs.mapRef.$mapPromise.then((map) => {
                let self = this
                google.maps.event.addListener(map, 'dragend', () => {
                    let center = map.getBounds().getCenter().toJSON()
                    self.address = {
                        ...self.address,
                        latitude: center.lat,
                        longitude: center.lng
                    }
                    self.getFormattedAddress(self.address.latitude, self.address.longitude)
                })
            })
        }
    },
    mounted() {
        this.geoLocate()
    },
    methods: {
        close() {
            this.$emit('close')
        },
        isValid() {
            this.errors = {}
            if (!this.address.country_code) {
                this.errors.country_code = this.$t('Select a country.')
            }
            if (!this.address.county) {
                this.errors.county = this.$t('Fill in county name.')
            }
            if (!this.address.city) {
                this.errors.city = this.$t('Fill in city name.')
            }
            if (!this.address.address_line_1) {
                this.errors.address_line_1 = this.$t('Fill in street name and number.')
            }
            if (!this.address.formatted_address || !this.address.latitude || !this.address.longitude) {
                this.errors.formatted_address = this.$t('Please select your position on map.')
                this.flash(this.errors.formatted_address, 'error')
            }
            return !Object.keys(this.errors).length
        },
        save() {
            if (!this.isValid()) {
                return false
            } else {
                const distance = getDistance({
                    lat: this.address.latitude,
                    lng: this.address.longitude
                }, this.$store.state.storeCoordinates)
                if (distance / 1000 > this.$store.state.deliveryRadius) {
                    this.flash(replaceFlags(this.$t('We cannot deliver to this address. Is too far. Maximum %s km far from our location is allowed.'), [this.$store.state.deliveryRadius]), 'error')
                    return false
                }
                this.$emit('save', {
                    ...this.address,
                    address_role: this.addressRole
                })
            }
        },
        setPlace(place) {
            this.address = {
                ...this.address,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng()
            };
            this.getFormattedAddress(this.address.latitude, this.address.longitude)
        },
        geoLocate() {
            navigator.geolocation.getCurrentPosition(position => {
                this.address = {
                    ...this.address,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                }
                this.getFormattedAddress(this.address.latitude, this.address.longitude)
            })
        },
        getFormattedAddress(lat, lng) {
            const self = this;
            if (google) {
                const geocoder = new google.maps.Geocoder()
                geocoder.geocode(
                    {
                        location: {
                            lat: lat,
                            lng: lng
                        }
                    },
                    function (results, status) {
                        if (status === google.maps.GeocoderStatus.OK) {
                            self.translateAddress(results[0])
                        }
                    }
                )
            }
        },
        findAddressComponent(address_components, type, field) {
            if (address_components && address_components.length > 0) {
                const component = address_components.find(x => x.types.includes(type))
                if (component) return component[`${field}`]
            }
            return "";
        },
        translateAddress(result) {
            this.address.formatted_address = result.formatted_address

            const address_components = result.address_components
            const street = this.findAddressComponent(
                address_components,
                "route",
                "long_name"
            )
            const streetNo = this.findAddressComponent(
                address_components,
                "street_number",
                "long_name"
            )
            this.address.address_line_1 = [street, streetNo].join(" ")

            this.address.county = this.findAddressComponent(
                address_components,
                "administrative_area_level_1",
                "long_name"
            )

            this.address.city = this.findAddressComponent(
                address_components,
                "locality",
                "long_name"
            )

            const country = this.findAddressComponent(
                address_components,
                "country",
                "short_name"
            )

            if (country) {
                this.address.country_code = String(country).toLowerCase()
            }

            this.$forceUpdate()
        },
        onPan(e) {
            this.address = {
                ...this.address,
                latitude: e.latLng.lat(),
                longitude: e.latLng.lng()
            };
            this.getFormattedAddress(this.address.latitude, this.address.longitude)
        }
    }
}
</script>

<style scoped>

</style>
