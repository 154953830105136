<template>
    <div class="container order-details">
        <h1 class="text-center">{{ $t('Order details') }}</h1>
        <Loading v-if="loading"/>
        <div v-else-if="order" class="text-center">
            <div>
                <h4>{{ $t('Order') }}: #{{ order.ref_no }}</h4>
                <small>{{ $t('Created on') }}: {{ formatDate(order.created_on) }}</small>
            </div>
            <div class="status" :class="`status-${order.status}`">
                <i class="fa fa-paper-plane-o" aria-hidden="true" v-if="order.status === 'new'"></i>
                <i class="fa fa-hourglass-start fa-spin" aria-hidden="true" v-if="order.status === 'pending'"></i>
                <i class="fa fa-car" aria-hidden="true" v-if="order.status === 'delivered'"></i>
                <i class="fa fa-flag-checkered" aria-hidden="true" v-if="order.status === 'completed'"></i>
                <i class="fa fa-times" aria-hidden="true" v-if="order.status === 'canceled'"></i>
                <div><small>{{ $t(order.status) }}</small></div>
            </div>
            <div>
                {{ $t('Delivery address') }}: {{ order.shipping_address }}
            </div>
            <div>
                <strong>{{ $t('Order total') }}:
                    <span class="price">{{ formatPrice(order.total_amount) }} RON</span>
                </strong>
            </div>
            <hr>
            <div class="products text-left" v-if="order.products && order.products.length">
                <div class="product-item" v-for="(product, i) in order.products" :key="i">
                    {{ product.quantity }} x {{ product.product_name }}:
                    <span class="price">{{ formatPrice(product.quantity * product.item_price) }} RON</span>
                </div>
            </div>
            <hr>
            <div>
                <router-link to="/account/orders" class="btn btn-default-inverted btn-block-xs-portrait">&laquo; {{ $t('Back to orders') }}</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/Loading";
import {formatDate, formatPrice} from "@/utils";

export default {
    name: "OrderDetails",
    components: {Loading},
    data() {
        return {
            loading: false,
            order: null
        }
    },
    mounted() {
        this.getOrder()
    },
    methods: {
        getOrder() {
            this.loading = true
            this.$store.dispatch('getCustomerOrder', {
                id: this.$route.params.id
            })
                .then(response => {
                    this.loading = false
                    this.order = response.data.data
                }).catch(error => {
                this.loading = false
                if (error.response.status === 405) {
                    this.flash(error.response.data.error, 'error')
                } else {
                    this.flash(this.$t('An error occurred. Please try again later. We are working on it.'), 'error')
                }
                this.$router.push('/account/orders')
            })
        },
        formatDate(date) {
            return formatDate(date, this.$i18n.locale)
        },
        formatPrice(price) {
            return formatPrice(price)
        },
    }
}
</script>

<style scoped>

</style>
