export const config = {
	NODE_ENV: 'production',
	APP_URL: 'https://chefs.rockstadt.ro',
	API_URL: 'https://apidev.rockstadt.ro',
	API_KEY: 'o3j8Tr2Fh0ZzW0bqv7ONLgSQwdgj9yz0IR7kOeYhuJD4uYkuUcYcjLGAZVbrTSrAC8Tq2SkhAdhJ9zfyfOB6aOEeeZh9dumplw1y',
	GOOGLE_API_KEY: 'AIzaSyCEKaavpt5CcRJkhBAB6ikbJTaJ2Yp1JTo',
	GOOGLE_CLIENT_ID: '492591312200-i2gg4589s7lt3b851u777j43f6lc5fk6.apps.googleusercontent.com',
	GOOGLE_CLIENT_SECRET: 'nH1yGyMKUymkXCcM6Me_GF3q',
	FACEBOOK_APP_ID: '3545683182194281'
}
