<template>
    <div class="products__item">
        <div class="row">
            <div class="col-xs-3 col-sm-2" @click="showDetails = true">
                <div class="product__image" :style="{ 'background-image': product.photo ? 'url(' + product.photo.url.thumbs + ')' : 'url(\'/images/no-image.svg\')' }"></div>
            </div>
            <div class="col-xs-6 col-sm-8" @click="showDetails = true">
                <h4>{{ product.name }}</h4>
                <small>{{ product.price_unit }}</small>
            </div>
            <div class="col-xs-3 col-sm-2 text-right">
                <span class="price">{{ formatPrice(product.price) }} RON</span>
                <div class="cart-actions">
                    <i v-if="quantity > 0" class="fa fa-minus-circle" @click="cartQuantity(-1)"></i>
                    <span v-if="quantity > 0" class="cart-quantity">{{ quantity }}</span>
                    <i class="fa fa-plus-circle" @click="cartQuantity(1)"></i>
                </div>
            </div>
        </div>
        <ProductModal :product="product" v-if="showDetails" @close="showDetails = false" @order="cartQuantity(1)"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProductModal from "@/components/ProductModal";
import {formatPrice} from "@/utils";

export default {
    name: "ProductListItem",
    components: {ProductModal},
    computed: {
        ...mapGetters({
            cart: 'GET_CART'
        }),
        quantity() {
            const cartProduct = this.cart.find(
                p => p.id === this.product.id && p.combination_key === this.product.combination_key
            )
            if (cartProduct) {
                return cartProduct.quantity
            } else {
                return 0
            }
        }
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            showDetails: false
        }
    },
    methods: {
        cartQuantity(value) {
            this.$emit('cartQuantity', this.product, value)
        },
        formatPrice(price) {
            return formatPrice(price)
        }
    }
}
</script>

<style scoped>

</style>
