<template>
    <div class="custom-modal-overlay product-detail">
        <div class="custom-modal">
            <div class="custom-modal-head">
                <div class="custom-modal-title text-primary">{{ product.name }}</div>
                <div class="custom-modal-close" @click="close()">&times;</div>
            </div>
            <div class="custom-modal-body">
                <div class="row">
                    <div class="col-xs-12 col-sm-6">
                        <div class="product-detail__image" :class="{ contain: !product.photo }" :style="{ 'background-image': product.photo ? 'url(' + product.photo.url.big + ')' : 'url(\'/images/no-image.svg\')' }"></div>
                    </div>
                    <div class="col-xs-12 col-sm-6">
                        <div class="product-detail__info">
                            <div class="wysiwyg" v-if="product.description" v-html="product.description"></div>
                            <div><span class="price">{{ product.price }} RON</span> / {{ product.price_unit }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="custom-modal-footer">
                <button type="button" class="btn btn-default btn-default-inverted" @click="close()">{{ $t('Close') }}</button>
                <button type="button" class="btn btn-success btn-success-inverted" @click="order()">{{ $t('Order') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductModal",
    props: {
        product: Object
    },
    methods: {
        close() {
            this.$emit('close')
        },
        order() {
            this.$emit('order')
            this.close()
        }
    }
}
</script>

<style scoped>

</style>
