<template>
    <div class="container">
        <h1 class="text-center">{{ $t('Recover password') }}</h1>
        <div class="row">
            <div class="col-xs-12 col-sm-6 col-sm-offset-3">
                <RecoverPasswordForm @sent="onSent"/>
                <hr/>
                <p class="text-center">
                    <router-link to="/sign-in">{{ $t('Sign in') }}</router-link>
                    &middot;
                    <router-link to="/sign-up">{{ $t('Sign up') }}</router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import RecoverPasswordForm from "@/components/RecoverPasswordForm";

export default {
    name: "RecoverPassword",
    components: {RecoverPasswordForm},
    mounted() {
        document.title = this.$t('Recover password') + ' - Rockstadt Chefs'
        this.$scrollToTop()
    },
    watch: {
        '$i18n.locale'() {
            document.title = this.$t('Recover password') + ' - Rockstadt Chefs'
        }
    },
    methods: {
        onSent(response) {
            this.flash(response.message, 'success')
        }
    }
}
</script>

<style scoped>

</style>
