<template>
    <div class="products__category">
        <div class="products__category__name" :class="'products__category--' + depth">
            {{ node.name }}
        </div>
        <ProductListItem v-if="node.products && node.products.length" v-for="(product, x) in node.products" :key="x" :product="product" @cartQuantity="cartQuantity"/>
        <NodeListItem v-if="node.children && node.children.length" v-for="(subnode,y) in node.children" :key="y" :node="subnode" :depth="depth + 1" @cartQuantity="cartQuantity"/>
    </div>
</template>

<script>
import ProductListItem from "@/components/ProductListItem";

export default {
    name: "NodeListItem",
    props: {
        node: {
            type: Object
        },
        depth: {
            type: Number,
            default: 1
        }
    },
    components: {
        ProductListItem
    },
    methods: {
        cartQuantity(product, value) {
            this.$emit('cartQuantity', product, value)
        }
    }
}
</script>

<style scoped>

</style>
